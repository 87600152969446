<template>
    <div id="home">
        <main class="intro">
            <div class="text">
                <h1 v-html="title"></h1>
                <span>
                    <span v-html="body"></span>
                    <span class="read-more" @click="scrollTo('about-us')">
                        Lees verder 
                        <svg width="60px" height="49px" viewBox="0 0 60 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g transform="translate(-1536.000000, -300.000000)" fill="#fff" fill-rule="nonzero">
                                    <polygon points="1571.85 348.28 1595.79 324.27 1571.85 300.4 1567.23 305.02 1583.47 321.05 1536.99 321.05 1536.99 327.56 1583.47 327.56 1567.23 343.59"></polygon>
                                </g>
                            </g>
                        </svg>
                    </span>
                </span>
            </div>
        </main>
        <div class="header">
            <div class="image-wrapper">
                <div class="desktop">
                    <img :data-srcSet="imageSrcset" data-sizes="auto" class="lazyload desktop">
                </div>
                <div class="mobile">
                    <img :data-srcSet="imageMobileSrcset" data-sizes="auto" class="lazyload mobile">
                </div>
            </div>
        </div>
        <aside class="menu">
            Direct naar
            <ul>
                <li @click="scrollTo('about-us')">
                    <svg width="60px" height="49px" viewBox="0 0 60 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(-1536.000000, -300.000000)" fill="#fff" fill-rule="nonzero">
                                <polygon points="1571.85 348.28 1595.79 324.27 1571.85 300.4 1567.23 305.02 1583.47 321.05 1536.99 321.05 1536.99 327.56 1583.47 327.56 1567.23 343.59"></polygon>
                            </g>
                        </g>
                    </svg>
                    Over ons
                </li>
                <li @click="scrollTo('cases')">
                    <svg width="60px" height="49px" viewBox="0 0 60 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(-1536.000000, -300.000000)" fill="#fff" fill-rule="nonzero">
                                <polygon points="1571.85 348.28 1595.79 324.27 1571.85 300.4 1567.23 305.02 1583.47 321.05 1536.99 321.05 1536.99 327.56 1583.47 327.56 1567.23 343.59"></polygon>
                            </g>
                        </g>
                    </svg>
                    Cases
                </li>
                <li @click="scrollTo('expertises')">
                    <svg width="60px" height="49px" viewBox="0 0 60 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(-1536.000000, -300.000000)" fill="#fff" fill-rule="nonzero">
                                <polygon points="1571.85 348.28 1595.79 324.27 1571.85 300.4 1567.23 305.02 1583.47 321.05 1536.99 321.05 1536.99 327.56 1583.47 327.56 1567.23 343.59"></polygon>
                            </g>
                        </g>
                    </svg>
                    Expertises
                </li>
                <li @click="scrollTo('contact')">
                    <svg width="60px" height="49px" viewBox="0 0 60 49" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g transform="translate(-1536.000000, -300.000000)" fill="#fff" fill-rule="nonzero">
                                <polygon points="1571.85 348.28 1595.79 324.27 1571.85 300.4 1567.23 305.02 1583.47 321.05 1536.99 321.05 1536.99 327.56 1583.47 327.56 1567.23 343.59"></polygon>
                            </g>
                        </g>
                    </svg>
                    Contact
                </li>
            </ul>
        </aside>
    </div>
</template>

<script> 
    import DefaultPage from '../../mixins/DefaultPage.vue'
    import scrollIntoView from 'scroll-into-view'
    
    export default {
        mixins: [DefaultPage],
        components: {
        },
        data: function() {
            return {
            }
        },
        computed: {
            title() {
                return this.$store.state.preloadedPages.home.title;
            },
            body() {
                return this.$store.state.preloadedPages.home.body;
            },
            imageSrcset() {
                return this.$store.state.preloadedPages.home.image.srcset;
            },
            imageMobileSrcset() {
                return this.$store.state.preloadedPages.home.imageMobile.srcset;
            },
        },
        methods: {
            scrollTo(id) {
                const el = document.getElementById(id);

                if(el) {
                    const scrollToEl = el.parentElement.parentElement;

                    if(scrollToEl) {
                        let leftOffset = -5;
                            
                        if(this.$store.state.isMobile) {
                            leftOffset = 0;
                        }

                        scrollIntoView(scrollToEl, {align:{leftOffset: leftOffset}});
                    }
                }
            }
        },
        mounted() {
            
        },
        beforeDestroy() {
        },
        watch: {
           
        }

    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';
    #home {
        display: flex;
        flex-flow: row wrap;
        padding: 4rem 1rem 1rem;

        .intro {
            display: flex;
            flex-flow: column;

            .text {
                @include font-med;
                max-width: 600px;

                > span {
                    display: inline-block;
                    padding-top: 2.25em;
                }

                .read-more {
                    display: none;
                    padding-top: 1em;
                    cursor: pointer;

                    svg {
                        height: .8em;
                        width: .8em;
                        transform: translate3d(0,0.12em,0);
                        animation: readmore 2.3s infinite;
                        animation-timing-function: cubic-bezier(0.65, 0, 0.35, 1);
                    }

                    @include orientationSwitch {
                        display: inline-block;
                    }
                }

                @include orientationSwitch {
                    padding: 2rem;
                    padding-left: 8rem;
                    @include fluid-type(padding-left, 1024px, 1320px, 80px, 160px);
                }
            }

            @include orientationSwitch {
                flex-flow: row nowrap;
                width: auto;
                align-items: center;

                .text {
                    max-width: 900px;
                }
            }
        }

        .header {
            position: relative;
            margin: 2rem 0 0;
            width: 100%;
            padding-bottom: 100%;

            .image-wrapper {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;

                .desktop, .mobile {
                    -webkit-mask-size: contain;
                    mask-size: contain;
                    -webkit-mask-position: center;
                    mask-position: center;
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                }

                .desktop {
                    height: 100%;
                    display: none;
                    -webkit-mask-image: url('/static/images/home-mask.svg');
                    mask-image: url('/static/images/home-mask.svg');
                }

                .mobile {
                    height: 100%;
                    display: block;

                    -webkit-mask-image: url('/static/images/home-mask-mobile.svg');
                    mask-image: url('/static/images/home-mask-mobile.svg');
                }
                

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }

                @include orientationSwitch {
                    .desktop {
                        display: block;
                    }

                    .mobile {
                        display: none;
                    }
                }
            }

            @include orientationSwitch {
                width: 100vh;
                padding-bottom: 0;
                margin: 4rem 0 2rem;
            }
        }

        .menu {
            display: flex;
            flex-flow: column;
            justify-content: center;
            padding: 2rem 0;

            ul {
                @include fluid-type(font-size, 480px, 1320px, $font-size*1.5, $font-size*3.5);
                line-height: 1.2;
                letter-spacing: -0.05em;
                list-style: none;
                padding-top: .25em;

                li {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    margin-bottom: .23em;

                    svg {
                        height: 1em;
                        width: auto;
                        margin-right: .5em;
                    }
                }
            }

            @include orientationSwitch {
                padding: 2rem 4rem;
                // flex: 0 0 auto;
            }
        }

        @include orientationSwitch {
            padding: 0;
            flex-flow: row nowrap;
        }
    } 

    @keyframes readmore {
        0%   { transform: translate3d(0,0.12em,0); }
        50% { transform: translate3d(0.3em,0.12em,0); }
        100% { transform: translate3d(0,0.12em,0); }
    }
</style>

