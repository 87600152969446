<script>
    export default {
        // mixins: [TranslationHelper],
        props: {
            content: {
                type: Object,
                required: false
            }
        },
        components: {
            TextComponent: () => import('@/js/components/contentBlocks/Text.vue' /* webpackChunkName: 'build/js/partials/Text' */),
            StatistiscComponent: () => import('@/js/components/contentBlocks/Statistics.vue' /* webpackChunkName: 'build/js/partials/Statistics' */),
            SlideshowComponent: () => import('@/js/components/contentBlocks/Slideshow.vue' /* webpackChunkName: 'build/js/partials/Slideshow' */),
            VideoComponent: () => import('@/js/components/contentBlocks/Video.vue' /* webpackChunkName: 'build/js/partials/Video' */),
            SpacerComponent: () => import('@/js/components/contentBlocks/Spacer.vue' /* webpackChunkName: 'build/js/partials/Spacer' */),
        },
        computed: {
            title() {
                return this.content.title ? this.content.title : '';
            },
            slug() {
                return this.content.slug ? this.content.slug : '';
            },
            pageClass() {
                return this.content.slug ? 'page-'+this.content.slug : '';
            }
        },
        mounted() {
           
        }
    }
</script>