<template>
    <div id="contact">
       <div class="image-and-copyright">
           <div class="content">
               <div class="copyright" v-html="copyright"></div>
               <img :srcset="imageSrcset">
           </div>
       </div>
    </div>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'
    
    export default {
        mixins: [DefaultPage],
        data: function() {
            return {
              
            }
        },
        computed: {
            imageSrcset() {
                return this.$store.state.preloadedPages.contact.image.srcset;
            },
            copyright() {
                return this.$store.state.preloadedPages.contact.copyright;
            }
        },
        methods: {
          
        },
        mounted() {
        
        },
        created() {
           
        }

    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    #contact {
        a {
            color: inherit;
            text-decoration: none;

            &:hover {
                color: inherit;
                text-decoration: none;
            }
        }

        .image-and-copyright {
            position: relative;
            height: 100%;
            // max-width: 100vw;

            .content {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }

                .copyright {
                    @include font-med;
                    position: absolute;
                    bottom: 1.5rem;
                    right: 2rem;
                    text-align: right;
                }
            }
        }

        @include orientationSwitch {
            .image-and-copyright {
                width: 66vw;
                height: 100%;
            }
        }
    }    
</style>

