<template>
    <div class="main-content">
        <div v-if="content && content.type && content.type === 'case'">
            <CaseFull :content="content"/>
        </div>
        <template v-else>
            <PageWrapper title="" :showBounce="false" pageColor="#FF4A00" menuColorClass="light">
                <template v-slot:content>
                    <Home/>
                </template>
            </PageWrapper>

            <PageWrapper title="Over ons" textColor="#001E46" :showBounce="true" prevPageColor="#FF4A00" pageColor="#00D782" menuColorClass="dark">
                <template v-slot:content>
                    <AboutUs/>
                </template>
            </PageWrapper>

            <PageWrapper title="Cases" textColor="#fff" :showBounce="true" prevPageColor="#00D782" pageColor="#001E46" menuColorClass="light">
                <template v-slot:content>
                    <CasesOverview/>
                </template>
            </PageWrapper>

            <PageWrapper title="Expertises" textColor="#001E46" :showBounce="true" prevPageColor="#001E46" pageColor="#D2E8E7" menuColorClass="dark">
                <template v-slot:content>
                    <Expertises/>
                </template>
            </PageWrapper>

            <PageWrapper title="Contact" :body="$store.state.preloadedPages.contact.body" textColor="#fff" :showBounce="true" prevPageColor="#D2E8E7" pageColor="#001E46" menuColorClass="light">
                <template v-slot:content>
                    <Contact/>
                </template>
            </PageWrapper>
        </template>
    </div>
</template>

<script>
    import PageNotFound from './PageNotFound'
    import PageWrapper from '@/js/components/PageWrapper'
    
    import Home from './pageTypes/Home'
    import CasesOverview from './pageTypes/CasesOverview'
    import Expertises from './pageTypes/Expertises'
    import AboutUs from './pageTypes/AboutUs'
    import Contact from './pageTypes/Contact'

    import CaseFull from './pageTypes/CaseFullPage'

    import { mapMutations } from 'vuex'
    
    export default {
        components: {       
            PageNotFound,
            
            Home,
            PageWrapper,
            CasesOverview,
            Expertises,
            AboutUs,
            Contact,

            CaseFull,
        },
        data: function() {
            return {
                loading: true,
                content: {},
                pageType: 'single'
            }
        },
        computed: {
            pageLoading: {
                get () {
                    return this.$store.state.pageLoading;
                },
                set (status) {
                    return this.$store.commit('setPageLoading', status);
                }
            }
        },
        methods: {
            ...mapMutations([
                'setAvailableTranslations',
                'setTheme'
            ]),
            loadContent: function(to) {
                this.pageLoading = true;
                
                return new Promise((resolve, reject) => {
                    const startTime = Date.now();

                    const urlPart1 = to ? to.params.part1 : this.$route.params.part1,
                          urlPart2 = to ? to.params.part2 : this.$route.params.part2,
                          availableLanguages = this.$store.state.availableLanguages;

                    let slug = null,
                        content = null;

                    // Check if part 1 of the url is language then set part 2 as the slug or get the homepage if part 2 is empty
                    if(availableLanguages.includes(urlPart1)) {
                        if(urlPart2) {
                            slug = urlPart2;
                        } else {
                            slug = 'home';
                        }
                    } else if(urlPart1) {
                        slug = urlPart1;
                    } else {
                        slug = 'home'
                    }

                    let currentLanguage     = this.$store.state.currentLanguage,
                        pageContentEndpoint = this.$store.state.apiEndpoints['page'].url+slug+'.json?lang='+currentLanguage;

                    this.$http.get(pageContentEndpoint, {crossdomain: true})
                        .then((result) => {
                            // Check if enough time has passed to play the loading animation
                            const endTime = new Date(),
                                  timeDiff = endTime - startTime;
               
                            const timeout = Math.max(0, 600-timeDiff);
                            
                            setTimeout(() => {
                                window.scrollTo(0,0);

                                // Set the content to the view component
                                this.setContent(result.data).then(() => {
                                    this.pageLoading = false;
                                    resolve();
                                });
                            }, timeout);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            },
            setContent: function(content) {
                if(content) {
                    return new Promise((resolve, reject) => {
                        if (content.type == 'home') {
                            this.$store.commit('setIsHome', true);
                        } else if(this.$store.state.isHome) {
                            this.$store.commit('setIsHome', false);
                        }

                        // const singleTypes = [];
                        // let pageType = null;

                        // if (singleTypes.indexOf(content.type) > -1) {
                        //     pageType = 'single';
                        // }

                        // this.pageType = pageType ? pageType : content.type;
                        this.pageType = content.type;
                        this.content  = content;

                        this.setAvailableTranslations(content.translations);
                        this.setMeta(content);
                        this.setStyle(content.styles, content.type);

                        resolve();
                    });

                }
            },
            setMeta: function(content) {
                const meta = content.meta;
                let baseTitle = null;

                // Set page title
                if(this.content && this.content.title) {             
                    baseTitle = this.$store.state.websiteData.title;

                    const titleSeperator = this.$store.state.websiteData.titleSeperator,
                          pageTitle      = this.content.title;

                    if(this.content.title !='Home') {
                        baseTitle = `${baseTitle} ${titleSeperator} ${pageTitle}`
                    }

                    document.title = baseTitle;
                }

                if(content.type != "case") { // Set sharing back to default
                    document.querySelector('meta[property="og:image"]').setAttribute("content", 'https://kollektiv-media.com/imager/images/contact/919/contact_3998b7d57f5b9d921cfe1e5273bfd011.jpg');
                    document.querySelector('meta[name="twitter:card"]').setAttribute("content", 'https://kollektiv-media.com/imager/images/contact/919/contact_3998b7d57f5b9d921cfe1e5273bfd011.jpg');
                    document.querySelector('meta[name="twitter:image"]').setAttribute("content", 'https://kollektiv-media.com/imager/images/contact/919/contact_3998b7d57f5b9d921cfe1e5273bfd011.jpg');
                }

                if(!meta) {
                    document.querySelector('meta[property="og:description"]').setAttribute("content", baseTitle);
                    document.querySelector('meta[name="twitter:description"]').setAttribute("content", baseTitle);
                }

                if(content.type == 'case') { // Set sharing information
                    if(baseTitle) {
                        document.querySelector('meta[property="og:title"]').setAttribute("content", baseTitle);
                        document.querySelector('meta[name="twitter:title"]').setAttribute("content", baseTitle);
                    }
                    
                    if(content.title) {
                        let description = `${content.title}`;
                        document.querySelector('meta[property="og:description"]').setAttribute("content", description);
                        document.querySelector('meta[name="twitter:description"]').setAttribute("content", description);
                    }
                    
                    if(content.image && content.image.url) {
                        document.querySelector('meta[property="og:image"]').setAttribute("content", 'https://kollektiv-media.com'+content.image.url);
                        document.querySelector('meta[name="twitter:card"]').setAttribute("content", 'https://kollektiv-media.com'+content.image.url);
                        document.querySelector('meta[name="twitter:image"]').setAttribute("content", 'https://kollektiv-media.com'+content.image.url);
                    }
                } else if(meta) { // Set meta information if set
                    if(document.querySelector('meta[name="description"]')) {
                        if(meta.description) {
                            document.querySelector('meta[name="description"]').setAttribute("content", meta.description);
                            document.querySelector('meta[property="og:description"]').setAttribute("content", meta.description);
                            document.querySelector('meta[name="twitter:description"]').setAttribute("content", meta.description);
                        } else if(this.$store.state.websiteData && this.$store.state.websiteData.metaDescription) {
                            document.querySelector('meta[name="description"]').setAttribute("content", this.$store.state.websiteData.metaDescription);
                            document.querySelector('meta[property="og:description"]').setAttribute("content", this.$store.state.websiteData.metaDescription);
                            document.querySelector('meta[name="twitter:description"]').setAttribute("content", this.$store.state.websiteData.metaDescription);
                        }
                    }
                }
            },
            setStyle(styles, pageType) {
                // If a color is set on the current entry it overrides automated colors
                // if(styles.bgColor) {
                //     if (styles.bgColor == 'blue') {
                //         this.$store.commit('setMenuBgClass', 'blue');
                //     } else if (styles.bgColor == 'blue-lighter') {
                //         this.$store.commit('setMenuBgClass', 'blue-lighter');
                //     } else {
                //         this.$store.commit('setMenuBgClass', 'white');
                //     }

                //     return;
                // }

                // // Set a menu bg color based on page type
                // const blueMenuBG = ['home'];
                // const blueLighterMenuBG = ['projectsOverview','contact'];

                // if (blueMenuBG.includes(pageType)) {
                //     this.$store.commit('setMenuBgClass', 'blue');
                // } else if (blueLighterMenuBG.includes(pageType)) {
                //     this.$store.commit('setMenuBgClass', 'blue-lighter');
                // } else {
                //     this.$store.commit('setMenuBgClass', 'white');
                // }
            }
        },
        created() {
            window.scrollTo(0,0);
            this.loadContent().catch((error) => {
                if(window.location.href.indexOf("404") == -1) {
                    this.$router.push({ path: '404' });
                }
            });
        },
        beforeRouteUpdate(to, from, next) {
            this.loadContent(to).then(() => {
                next();
            })
            .catch((error) => {
                if(window.location.href.indexOf("404") == -1) {
                    this.$router.push({ path: '404' });
                } else {
                     this.pageLoading = false;
                }
            });
        },
        beforeDestroy() {

        }
    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';
    
    .main-content {
        width: 100%;
        min-height: 768px;

        > div {
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
        }
        
        @include orientationSwitch {
            height: 100%;
            display: flex;
            flex-flow: row nowrap;
        }
    }
</style>