<template>
    <article id="case-full">
        <header>
            <div class="column image">
                <img :srcset="content.image.srcset">
            </div>
            <div class="column text">
                <span>
                    <span class="client">{{content.client}}</span>
                    <h2>{{content.title}}</h2>
                </span>
                <div class="case-header-stats">
                    <div class="table">
                        <div class="row">
                            <div class="cell">
                                Jaar
                            </div>
                            <div class="cell">
                                {{content.year}}
                            </div>
                        </div>
                        <div class="row">
                            <div class="cell">
                                Expertises
                            </div>
                            <div class="cell">
                                <span 
                                    v-for="(expertise, index) in content.expertises" 
                                    :key="'expertise_'+index"
                                    @click="openCase(caseObj, index)"
                                >
                                    <span v-if="index == 0">{{expertise.title.charAt(0).toUpperCase() + expertise.title.slice(1)}}</span><span v-else>{{expertise.title}}</span><span v-if="index != Object.keys(content.expertises).length - 1">, </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <main>
            <Case :content="content"/>
        </main>
        <footer>
            <span @click="closeCase">
                terug 
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <g fill="none" fill-rule="evenodd" transform="rotate(180 20 20)">
                        <rect width="40" height="40" fill="#001E46" rx="20"/>
                        <path class="inverted" fill="#D2E8E7" d="M10.5,21 L30.5,21 C30.7761424,21 31,20.7761424 31,20.5 C31,20.2238576 30.7761424,20 30.5,20 L10.5,20 C10.2238576,20 10,20.2238576 10,20.5 C10,20.7761424 10.2238576,21 10.5,21 Z" transform="rotate(90 20.5 20.5)"/>
                        <path class="inverted" fill="#D2E8E7" d="M6.5,21 L26.5,21 C26.7761424,21 27,20.7761424 27,20.5 C27,20.2238576 26.7761424,20 26.5,20 L6.5,20 C6.22385763,20 6,20.2238576 6,20.5 C6,20.7761424 6.22385763,21 6.5,21 Z" transform="rotate(90 16.5 20.5)"/>
                        <path class="inverted" fill="#D2E8E7" d="M14.5,21 L34.5,21 C34.7761424,21 35,20.7761424 35,20.5 C35,20.2238576 34.7761424,20 34.5,20 L14.5,20 C14.2238576,20 14,20.2238576 14,20.5 C14,20.7761424 14.2238576,21 14.5,21 Z" transform="rotate(90 24.5 20.5)"/>
                    </g>
                </svg>
            </span>
        </footer>
    </article>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'
    import Case from './_Case.vue'
    import scrollIntoView from 'scroll-into-view';
    
    export default {
        mixins: [DefaultPage],
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        components: {
            Case
        },
        data: function() {
            return {
            }
        },
        computed: {
            closeCurrentCase() {
                return this.$store.state.closeCase;
            },
            caseActive: {
                get() {
                        return this.$store.state.caseActive;
                },
                set(bool) {
                        this.$store.commit('setCaseActive', bool)
                }
            },
        },
        methods: {
            closeCase() {
                this.$router.push({ path: '/'},
                    () => {
                        const el = document.getElementById('cases');

                        if(el) {
                            this.open = false;
                            const scrollToEl = el.parentElement.parentElement;

                            if(scrollToEl) {
                                let leftOffset = -5;
                                    
                                if(this.$store.state.isMobile) {
                                    leftOffset = 0;
                                }

                                scrollIntoView(scrollToEl, {align:{leftOffset: leftOffset}, time: 0});
                                this.$store.state.closeCase = false;
                                this.caseActive = false;
                            }
                        }
                    }
                );
            }
        },
        watch: {
            closeCurrentCase(val) {
                if(val === true) {
                    this.closeCase();
                }
            }
        },
        created() {
            this.$store.state.menuColorClass = 'light';
            this.$store.state.scrollHorizontal = false;

            if(this.$store.state.isMobile) {
                this.$store.state.closeCase = 'canBeClicked'
                this.caseActive = true;
            }
        },
        beforeDestroy() {
            this.$store.state.scrollHorizontal = true;
        }

    }
</script>

<style lang="scss" scoped>
    @import '@/scss/helper.scss';

    #case-full {
        flex: 1 0 100%;
        display: flex;
        flex-flow: row wrap;

        header, main, footer {
            flex: 1 0 100%;
            max-width: 100vw;
        }

        main, footer {
            z-index: 1;
        }

        header {
            flex: 1 0 auto;
            display: flex;
            flex-flow: row wrap;
            background: $blue;

            .column {
                flex: 0 0 100%;
            }

            .image {
                @include aspectRatio(335,375);
                position: relative;
                overflow: hidden;
                max-height: 715px;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    height: 100vh;
                    width: auto;
                }

                @include mobile {
                    height: 100vh;
                    max-height: none;

                    &::before {
                        display: none;
                    }
                }
            }

            .text {
                display: flex;
                justify-content: space-between;
                flex-flow: column;
                padding: 2rem 1rem;
                color: $white;

                h2 {
                    max-width: 320px;
                    padding: .5rem 0;
                }

                .client {
                    @include font-med;
                    color: $blue-light;
                }

                .case-header-stats {
                    padding: 1rem 0 0 0;

                    .table {
                        width: 100%;

                        .row {
                            margin-top: .5rem;
                            display: flex;
                            flex-flow: row wrap;
                            flex: 1 0 100%;

                            .cell {
                                padding-right: 3rem;
                                padding-top: .5rem;

                                &:first-child {
                                    min-width: 8rem;
                                }
                            }
                        }

                        
                    }
                }

                @include mobile {
                    padding: 1rem 2rem 1rem 2rem;
                }
            }

            
            @include mobile {
                position: sticky;
                top: 0;
                height: calc(var(--vh, 1vh) * 100);
                flex-flow: row nowrap;

                .column {
                    flex: 0 0 50%;
                }

                .text {
                    padding: 4rem 2rem 2rem 2rem;
                }
            }
        }

        main {
            background-color: $white;
            color: $blue;
        }

        footer {
            background-color: $blue-light;
            color: $blue;
            text-align: center;
            
            > span {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 2rem;
                cursor: pointer;
                @include font-med;

                svg {
                    margin-left: .75em;
                }
            }

            @include mobile {
                padding: 1rem;
            }
        }
    }
</style>

