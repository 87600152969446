<template>
    <div id="about-us">
        <ul class="people">
            <li class="half">
                <div class="column text">
                    <span v-html="$store.state.preloadedPages.about.body"></span>
                </div>
            </li>
            <li 
                v-for="(person, index) in people" 
                :key="'expertise_'+index"
            >
                <div class="column image">
                    <img :srcset="person.image.srcset">
                </div>
                <div class="column text">
                    <span>
                        <h2>{{person.name}}</h2>
                        <span v-html="person.body"></span>
                    </span>
                    <span class="contact" v-html="person.contactBody"></span>
                </div>
            </li>
        </ul>
        <aside class="clients">
            <h2>{{clientsTitle}}</h2>
            <span class="logos">
                <img 
                    v-for="(logo, index) in logos" 
                    :key="'logo_'+index"
                    :srcset="logo"
                >
            </span>
        </aside>
    </div>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'

    
    export default {
        mixins: [DefaultPage],
        components: {
            
        },
        data: function() {
            return {

            }
        },
        computed: {
            people() {
                return this.$store.state.preloadedPages.about.people;
            },
            clientsTitle() {
                return this.$store.state.preloadedPages.about.clientsTitle;
            },
            logos() {
                return this.$store.state.preloadedPages.about.logos;
            }
        },
        methods: {

        },
        mounted() {
            
        },
        created() {
           
        }

    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    #about-us {
        display: flex;
        flex-flow: row wrap;
        max-width: 100vw;
        overflow: hidden;

        ul.people {
            flex: 1 0 100%;
            list-style: none;
            display: flex;
            flex-flow: row wrap;
            margin-top: 1rem;
           
            li {
                flex: 1 0 100%;
                background: $white;
                color: $blue;
                display: flex;
                flex-flow: row wrap;
                margin: 1px 0 0 0;

                .column {
                    flex: 1 0 100%;
                }

                .image {
                    @include aspectRatio(335, 375);
                    width: 100%;

                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        object-fit: cover;
                        width: 100%;
                        height: 101%;
                    }

                    @include orientationSwitch {
                        &::before {
                            display: none;
                        }
                    }
                }

                .text {
                    padding: 2rem 1rem 2rem 1rem;
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;

                    .contact {
                        @include font-med;
                        padding-top: 2em;
                    }
                }

                &.half {
                    .text {
                        margin-top: 1em;
                    }
                }

                @include orientationSwitch {
                    flex-basis: 50rem;
                    width: 50rem;
                    margin: 0;
                    flex-flow: row nowrap;
                    flex: 1 0 auto;

                    .text {
                        padding: 4rem 2rem 2rem 2rem;
                    }

                    .column {
                        flex: 1 0 50%;
                    }
                    
                    &.half {
                        flex-basis: 25rem;
                        width: 25rem;
                    }

                }
            }

            @include orientationSwitch {
                flex-flow: row nowrap;
                flex: 1 0 auto;
                height: 100%;
                margin-top: 0;
            }
        }

        .clients {
            flex: 1 0 100%;
            padding: 4rem 1rem 2rem 1rem;

            h2 {
                color: $blue; 
                max-width: 25rem;
                padding-bottom: 4rem;
            }

            .logos {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 2rem;

                img {
                    object-fit: contain;
                    object-position: left center;
                    max-height: 2.5rem;
                    width: 100%;
                }
            }

            @include orientationSwitch {
                padding: 4rem 2rem 2rem 2.5rem;

                .logos {
                    grid-template-columns: 1fr 1fr 1fr;
                }

                flex: 1 0 auto;
            }
        }

        @include orientationSwitch {
            flex-flow: row nowrap;
            max-width: none;
        }
    }
</style>

