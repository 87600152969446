<template>
	<div id="app" v-if="!initializing" :class="{'is-mobile': isMobile, 'hide-overflow': $store.state.hideOverflow}">
		<MainMenu />
		<router-view/>
		<Loader/>
	</div>
</template>

<script>
	import MainMenu from '@/js/components/MainMenu'

	import Loader from '@/js/components/Loader'

	import { mapGetters, mapState } from 'vuex'

	export default {
			name: 'App',
			components: {
				MainMenu,
				Loader
			},
			data: function() {
					return {
							firstRun: true,
							resizeTimeout: null,
					}
			},
			computed: {
				initializing: {
						get() {
								return this.$store.state.initializing;
						},
						set(bool) {
								this.$store.commit('setInitializing', bool)
						}
				},
				...mapGetters([
						'langPrefix'
				]),
				availableLanguages: function() {
						return this.$store.state.websiteData ? this.$store.state.websiteData.availableLanguages : null;
				},
				currentLanguage: {
						get() {
								return this.$store.state.currentLanguage;
						},
						set(language) {
								this.$store.commit('setCurrentLanguage', language)
						}
				},
				isMobile: {
						get() {
								return this.$store.state.isMobile;
						},
						set(bool) {
								this.$store.commit('setIsMobile', bool)
						}
				},
			},
			methods: {
				init: function (startup) {
					if(!this.initializing || startup) {
						this.initializing = true;
						const startTime = Date.now();
						
						let currentLanguage        = this.$store.state.currentLanguage,
								websiteContentEndpoint = this.$store.state.apiEndpoints['website'].url+'?lang='+currentLanguage;

						this.$http.get(websiteContentEndpoint, {crossdomain: true})
								.then((result) => { 
										this.content = result.data.data[0];
										
										// Check if enough time has passed to play the loading animation
										const endTime = new Date(),
													timeDiff = endTime - startTime;
				
										const timeout = Math.max(0, 600-timeDiff);
										
										setTimeout(() => {
												window.scrollTo(0,0);

												// Set the content to the view component
												this.$store.commit('setWebsiteData', result.data.data[0]);
												this.initializing = false;

										}, timeout);
								})
								.catch((error) => {
										console.log(error);
								});
					}
				},
				scrollHorizontal(e) {
					if(!this.isMobile && this.$store.state.scrollHorizontal === true) {
						if (!e.deltaY) {
							return;
						}

						let deltaX = JSON.parse(JSON.stringify(e.deltaX));
						let deltaY = JSON.parse(JSON.stringify(e.deltaY));
						/*
						// Check if firefox and if so then double scrolling speed
						// This is a kind of dirty fix to adjust scrolling speed in firefox because for some reason its slow normally
						*/
						if(!!window.sidebar) {
							console.log('firefox');
							deltaX = deltaX*12;
							deltaY = deltaY*12;
						}
						
						e.currentTarget.scrollLeft += deltaY + deltaX;
						e.preventDefault();
					}
				},
				onScreenResize() {
					document.documentElement.style.setProperty('--vh', `${window.innerHeight/100}px`);
					const mql = window.matchMedia('(max-width: 1023px)');

					if(mql.matches && this.isMobile === false) {
						window.scrollTo(0,0);
					}

					if(mql.matches) {
						this.isMobile = true;
					} else {
						this.isMobile = false;
					}
				}
			},
			watch: {
				currentLanguage(lang) {
						this.init();
				},
				'$store.state.hideOverflow': function(hideOverflow) {
					if(hideOverflow) {
						document.body.style.overflow = "hidden";
					} else {
						document.body.style.overflow = "auto";
					}
				}
			},
			created() {
				// Set the language on the first run
				this.$store.commit('setAvailableLanguages', window.availableLanguages);

				const lang = this.$route.params.part1;

				if(lang && window.availableLanguages.includes(lang)) {
						this.currentLanguage = lang;
				} else {
					this.currentLanguage = window.availableLanguages[0];
				}

				const element = document.scrollingElement || document.documentElement;
				
				element.addEventListener('wheel', this.scrollHorizontal, { passive: false });
				window.addEventListener('resize', this.onScreenResize, { passive: false });
				window.addEventListener('orientationchange', this.onScreenResize, { passive: false });
				this.onScreenResize();
				
				this.init(1);
			}
	};
</script>

<style lang="scss">
		@import '@/scss/base.scss';

		#app {
			display: flex;

			&.hide-overflow {
				overflow: hidden;
			}

			@include orientationSwitch {
				height: 100%;
			}
		}
</style>