<template>
    <article class="page-wrapper" :style="pageStyle">
        
        <!-- <intersect v-if="showBounce" @enter="bounceInView = true" @leave="bounceInView = false"> -->
        <div v-if="showBounce" class="bounce" ref="bounce" :style="bounceStyle">
            <svg :style="scaleStyle" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 293 741">
                <path :fill="pageColor" id="shape-a" d="M284.653155,3.84098957 L2.81501171,364.35137 C-0.014030616,367.970111 -0.0140055699,373.050756 2.81507243,376.669469 L284.642712,737.159088 C286.53795,739.583313 289.443746,741 292.520887,741 L729,741 C734.522847,741 739,736.522847 739,731 L739,10 C739,4.4771525 734.522847,-1.01453063e-15 729,0 L292.531391,0 C289.454216,2.01051931e-14 286.548391,1.41671868 284.653155,3.84098957 Z"/>
            </svg>
        </div>
        <!-- </intersect> -->

        <intersect @enter="pageActive" :threshold="[0.25]">
            <div class="content-wrapper" ref="contentWrapper" :style="contentStyle">
                <div v-if="title" class="title" :style="{color: textColor}">
                    <span class="intro-text" v-if="body" v-html="body"></span>
                    <h1>{{title}}</h1>
                </div>
                <div class="content">
                    <slot name="content"></slot>
                </div>
            </div>
        </intersect>
        
    </article>
</template>

<script>
    import Intersect from 'vue-intersect';

    export default {
        components: {
            Intersect,
        },
        props: [
            'title',
            'body',
            'textColor',
            'showBounce',
            'pageColor',
            'prevPageColor',
            'menuColorClass'
        ],
        data() {
            return {
                bounceInView: false,

                pageStyle: {},
                contentStyle: {},
                bounceStyle: {},
                scaleStyle: {},

                // scrollspeed
                lastPos: null

            };
        },
        computed: {

        },
        methods: {
            pageActive() {
                this.$store.state.menuColorClass = this.menuColorClass;
            },
            onScroll(e) {
                if(!this.bounceInView) return;

                const elBB = this.$el.getBoundingClientRect();
                const newPos = window.scrollX;
                const bounceBB = this.$refs['bounce'].getBoundingClientRect();

                if (this.lastPos != null ){
                    var delta = newPos - this.lastPos;
                }

                if(Math.sign(delta) == 1 && elBB.x < bounceBB.width) {
                    // If scrolling forwards and near left edge
                    this.scaleStyle = this.scaleStyle = {
                                        transitionDuration: '1s',
                                        transform:'scaleX(0)'
                                    };

                } else if(elBB.x < bounceBB.width) {
                    // If scrolling backwards and near left edge
                    this.scaleStyle = {
                                    transitionDuration: '.75s',
                                    transform:'scaleX(1)'
                                };

                }

                this.lastPos = newPos;
            }
        },
        mounted() {
            if(this.showBounce) {
                window.addEventListener("scroll", this.onScroll, {passive: false});
            }
        },
        created() {
            this.bounceStyle = {
                backgroundColor: this.prevPageColor
            };

            this.contentStyle = {
                backgroundColor: this.pageColor
            };

            this.pageStyle = {
                backgroundColor: this.pageColor,
                zIndex: this.title == 'Cases' ? '2':''
            };
        }
    };
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    .page-wrapper {
        position: relative;
        color: $white;

        .bounce {
            display: none;
            flex: 1 0 auto;
            position: relative;
            overflow: hidden;
            height: 100%;
            z-index: 1;
            // transition: opacity .5s cubic-bezier(0.65, 0, 0.35, 1);
            width: 39.541160593vh;
            
            svg {
                // transition: transform 1s cubic-bezier(0.65, 0, 0.35, 1);
                transform-origin: center right;
                height: 100%;
                max-height: 100vh;
                width: 39.541160593vh;

                // Dirty safari hack to fix a hairline
                @media not all and (min-resolution:.001dpcm) { 
                    @supports (-webkit-appearance:none) {
                        transform: translateX(1px);
                    }
                }
            }

            @include orientationSwitch {
                display: block;
                // position: absolute;
                // top: 0;
                // left: -39.541160593vh;
            }
        }

        .content-wrapper {
            display: flex;
            flex-flow: row wrap;
            width: 100%;

            > .title {
                display: flex;
                flex: 1 0 100%;
                flex-flow: column;
                justify-content: space-between;
                position: relative;
                padding: 2rem 1rem 0 1rem;
                left: 0;
                

                h1 {
                    order: -1;
                    padding: .65em 0 .25em 0;
                    margin-top: auto;

                    @include orientationSwitch {
                        order: initial
                    }
                }

                .intro-text {
                    @include font-med;
                    max-width: 600px;
                    padding: 4rem 0 2em 0;

                    p:not(:last-child) {
                        padding-bottom: 1em;
                    }
                }
            }

            > .content {
                display: flex;
                background-color: inherit;
                z-index: 1;
                width: 100%;
                overflow: hidden;
            }

            @include orientationSwitch {
                flex-flow: row nowrap;

                > .title {
                    flex: 1 0 auto;
                    position: sticky;
                    padding: 0 6.75rem 0 2rem;

                    h1 {
                        padding: 0 0 2rem 0;
                    }
                }

                > .content {
                    overflow: auto;
                }
            }
        }

        @include orientationSwitch {
            display: flex;
            flex: 1 0 auto;
            height: 100%;
            // padding-right: 39.541160593vh;

            &:last-child {
                padding-right: 0;
            }
        }
    }
</style>