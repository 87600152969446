<template>
    <div id="cases">
        <ul class="case-thumbnails">
            <li 
                v-for="(caseObj, index) in cases" 
                :key="'case_'+index"
                @click="openCase(caseObj, index)"
            >
                <div class="thumb" :ref="'thumb_'+index">
                    <div class="column image">
                        <img :srcset="caseObj.image.srcset">
                    </div>
                    <div class="column text">
                        <span>
                            <span class="client">{{caseObj.client}}</span>
                            <h2>{{caseObj.title}}</h2>
                        </span>
                        <span class="see-case">
                            Bekijk case
                            <svg width="22px" height="18px" viewBox="0 0 22 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g transform="translate(-1151.000000, -807.000000)">
                                        <rect id="Rectangle-Copy" fill="#001E46" x="0" y="0" width="3403" height="873"></rect>
                                        <g transform="translate(514.000000, 0.000000)">
                                            <rect fill="#001E46" x="460" y="0" width="460" height="873"></rect>
                                            <polygon fill="#D2E8E7" fill-rule="nonzero" points="649.875 824.1 658.425 815.525 649.875 807 648.225 808.65 654.025 814.375 637.425 814.375 637.425 816.7 654.025 816.7 648.225 822.425"></polygon>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>
                </div>
            </li>
        </ul>

        <article class="case-wrapper" ref="case" :class="{'open': showHeader, 'animate': animateHeader, 'enable-scroll': enableScroll}">
            <div class="scroll-top-ghost" ref="scrollTopGhost"></div>
            <header class="case-header" v-if="activeCase" ref="caseHeader">
                <div class="column image">
                    <img :srcset="activeCase.image.srcset">
                </div>
                <div class="column text">
                    <span>
                        <span class="client">{{activeCase.client}}</span>
                        <h2>{{activeCase.title}}</h2>
                    </span>
                    <div class="case-header-stats">
                        <div class="table">
                            <div class="row">
                                <div class="cell">
                                    Jaar
                                </div>
                                <div class="cell">
                                    {{activeCase.year}}
                                </div>
                            </div>
                            <div class="row">
                                <div class="cell">
                                    Expertises
                                </div>
                                <div class="cell">
                                    <span 
                                        v-for="(expertise, index) in activeCase.expertises" 
                                        :key="'expertise_'+index"
                                        @click="openCase(caseObj, index)"
                                    >
                                        <span v-if="index == 0">{{expertise.title.charAt(0).toUpperCase() + expertise.title.slice(1)}}</span><span v-else>{{expertise.title}}</span><span v-if="index != Object.keys(activeCase.expertises).length - 1">, </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <main>
                <Case v-if="activeCaseContent" :content="activeCaseContent"/>
            </main>
            <footer>
                <span @click="closeCase">
                    terug 
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                        <g fill="none" fill-rule="evenodd" transform="rotate(180 20 20)">
                            <rect width="40" height="40" fill="#001E46" rx="20"/>
                            <path class="inverted" fill="#D2E8E7" d="M10.5,21 L30.5,21 C30.7761424,21 31,20.7761424 31,20.5 C31,20.2238576 30.7761424,20 30.5,20 L10.5,20 C10.2238576,20 10,20.2238576 10,20.5 C10,20.7761424 10.2238576,21 10.5,21 Z" transform="rotate(90 20.5 20.5)"/>
                            <path class="inverted" fill="#D2E8E7" d="M6.5,21 L26.5,21 C26.7761424,21 27,20.7761424 27,20.5 C27,20.2238576 26.7761424,20 26.5,20 L6.5,20 C6.22385763,20 6,20.2238576 6,20.5 C6,20.7761424 6.22385763,21 6.5,21 Z" transform="rotate(90 16.5 20.5)"/>
                            <path class="inverted" fill="#D2E8E7" d="M14.5,21 L34.5,21 C34.7761424,21 35,20.7761424 35,20.5 C35,20.2238576 34.7761424,20 34.5,20 L14.5,20 C14.2238576,20 14,20.2238576 14,20.5 C14,20.7761424 14.2238576,21 14.5,21 Z" transform="rotate(90 24.5 20.5)"/>
                        </g>
                    </svg>
                </span>
            </footer>
        </article>
    </div>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'
    import Case from './_Case.vue'
    import scrollIntoView from 'scroll-into-view';

    
    export default {
        mixins: [DefaultPage],
        components: {
            Case
        },
        data: function() {
            return {
                activeCase: null,
                activeCaseContent: null,
                activeCaseEl: null,
                showHeader: false,
                animateHeader: false,
                enableScroll: false,
                oldMenuColor: null,
            }
        },
        computed: {
            closeCurrentCase() {
                return this.$store.state.closeCase;
            },
            cases() {
                return this.$store.state.preloadedPages.cases;
            },
            caseActive: {
                get() {
                        return this.$store.state.caseActive;
                },
                set(bool) {
                        this.$store.commit('setCaseActive', bool)
                }
            },
        },
        methods: {
            openCase(caseObj, index) {
                // Handle mobile - use router
                if(this.$store.state.isMobile) {
                    this.$router.push({ path: '/'+caseObj.slug });
                    return;
                }

                history.pushState({}, '', '/'+caseObj.slug)
                
                this.oldMenuColor = this.$store.state.menuColorClass;

                // Default - Load case in and show animation
                this.$store.state.scrollHorizontal = false;
                this.activeCase = caseObj;

                let currentLanguage = this.$store.state.currentLanguage,
                pageContentEndpoint = this.$store.state.apiEndpoints['page'].url+caseObj.slug+'.json?lang='+currentLanguage;

                const loadDataPromise = this.$http.get(pageContentEndpoint, {crossdomain: true});

                let thumbEl = this.$refs['thumb_'+index][0];
                this.activeCaseThumbEl = thumbEl;

                let caseEl = this.$refs['case'];
                
                const animateCasePromise = new Promise((resolve, reject) => {
                    this.caseActive = true;
                    scrollIntoView(thumbEl, {cancellable: false, time: 500}, (type) => {
                        this.showHeader = true;
                        setTimeout(() => {
                            this.animateHeader = true;
                            
                            setTimeout(() => {
                                this.$store.state.hideOverflow = true;
                                resolve();
                            }, 600);
                        }, 300);
                    });
                });
                                
                Promise.all([loadDataPromise, animateCasePromise]).then((results) => {
                    results.forEach(result => {
                        if(result && result.data) {
                            this.activeCaseContent = result.data;
                        }
                    });

                    if(!this.activeCaseContent) {
                        this.closeCase();
                    }

                    this.$store.state.closeCase = 'canBeClicked';

                    this.enableScroll = true;
                    caseEl.focus();
                }).catch(error => {
                    console.log(error);
                    this.closeCase();
                });

            },
            closeCase() {
                // Handle mobile - use router
                if(this.$store.state.isMobile) {
                    this.$router.push({ path: '/' });
                    return;
                }

                history.pushState({}, '', '/')

                let scrollTopGhost = this.$refs['scrollTopGhost'];
                let caseEl = this.$refs['case'];
                this.$store.state.hideOverflow = false;
                
                scrollIntoView(scrollTopGhost, {cancellable: false, time: 500}, (type) => {
                    this.enableScroll = false;
                    scrollIntoView(this.activeCaseThumbEl, {cancellable: false, time: 0}, (type) => {
                        this.animateHeader = false;
                        setTimeout(() => {
                            this.showHeader = false;
                            setTimeout(() => {
                                this.$store.state.scrollHorizontal = true;
                                this.activeCaseContent = null;
                                this.caseActive = false;
                                this.$store.state.closeCase = false;
                                this.$store.state.menuColorClass = this.oldMenuColor;
                            }, 300);
                        }, 600);
                    });
                });
            }
        },
        watch: {
            caseActive(bool) {
                if(!bool && this.activeCase && !this.$store.state.scrollHorizontal) {
                    this.closeCase();
                }
            },
            closeCurrentCase(val) {
                if(val === true) {
                    this.closeCase();
                }
            }
        },
        created() {
           
        }

    }
</script>

<style lang="scss" scoped>
    @import '@/scss/helper.scss';

    #cases {
        position: relative;
        flex: 1 0 100%;
        display: flex;
        height: 100%;

        .case-thumbnails {
            display: block;
            flex-flow: row wrap;
            flex: 1 0 100%;
            padding: 0;
            margin: 0;
            list-style: none;
            padding: 1rem;

            li {
                padding: 0;
                cursor: pointer;
                margin: 0 0 1.25rem 0;
            }

            @include mobile {
                li {
                    height: 70vw;
                }
            }

            @include orientationSwitch {
                padding: 0;
                display: flex;
                height: 100%;

                li {
                    max-width: 80vw;
                    min-width: 55rem;
                    flex-basis: 55rem;
                    margin: 0 2rem 0 0;
                    height: 100%;
                }
            }
        }

        .thumb, header.case-header {
            display: flex;
            flex-flow: row wrap;
            height: 100%;
            transition: padding .6s cubic-bezier(0.65, 0, 0.35, 1);

            .column.image, .column.text {
                flex: 1 0 100%;
                overflow: hidden;
                transition: flex .6s cubic-bezier(0.65, 0, 0.35, 1);
            }

            .image {
                @include aspectRatio(335,375);
                position: relative;

                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    height: 100vh;
                    width: auto;
                }

                @include mobile {
                    &::before {
                        display: none;
                    }
                }
            }

            .text {
                display: flex;
                justify-content: space-between;
                flex-flow: column;
                padding: 1rem 0;

                h2 {
                    color: $white;
                    max-width: 420px;
                    padding: .5rem 0;
                }

                .client, .see-case {
                    @include font-med;
                    color: $blue-light;
                }

                .see-case {
                    svg {
                        margin-left: 0.25em;
                    }
                }

                @include mobile {
                    padding: 1rem 2rem 1rem 2rem;
                }
            }

            
            @include mobile {
                flex-flow: row nowrap;

                .column.image {
                    flex: 1 0 40%;
                }

                .column.text {
                    flex: 1 0 60%;
                }
            }

            @include orientationSwitch {
                flex: 1 0 100%;
                padding: 4rem 0 2rem 0;

                .text {
                    padding: 4rem 2rem 0 2rem;
                }
            }
        }

        .case-wrapper {
            pointer-events: none;
            opacity: 0;
            position: fixed;
            display: flex;
            flex-flow: row wrap;
            left: 50%;
            top: 0;
            width: 55rem;
            height: 100%;
            transform: translateX(-50%);
            background-color: $blue;
            transition: opacity 0.25s cubic-bezier(0.65, 0, 0.35, 1), width .6s cubic-bezier(0.65, 0, 0.35, 1);
            z-index: 5;

            header.case-header {
                position: sticky;
                top: 0;

                .case-header-stats {
                    padding-bottom: 2rem;

                    .table {

                        .row {
                            display: flex;
                            opacity: 0;
                            transform: translateY(.4em);
                            transition: all 0.35s cubic-bezier(0.65, 0, 0.35, 1);
                            transition-delay: 0;
                            

                            .cell {
                                padding-right: 3rem;
                                padding-top: .5rem;

                                &:first-child {
                                    min-width: 8rem;
                                }
                            }
                        }

                        
                    }
                }
            }

            main, footer {
                width: 100%;
                z-index: 1;
            }

            main {
                background-color: $white;
                color: $blue;
            }

            footer {
                background-color: $blue-light;
                color: $blue;
                text-align: center;

                > span {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 2rem;
                    cursor: pointer;
                    @include font-med;

                    svg {
                        margin-left: .75em;
                    }
                }

                @include mobile {
                    padding: 1rem;
                }
            }

            &.open {
                pointer-events: all;
                opacity: 1;
            }

            &.animate {
                width: 100%;
                
                header.case-header {
                    width: 100%;
                    padding: 0;

                    @include mobile {
                        .column.image {
                            flex: 1 0 50%;
                        }

                        .column.text {
                            flex: 1 0 50%;
                        }
                    }

                    .case-header-stats {
                                
                        .table {

                            .row {
                                opacity: 1;
                                transform: translateY(0);
                                transition-delay: .6s;

                                &:last-child {
                                    transition-delay: .7s;
                                }
                            }
                                
                        }
                    }
                }
            }

            &.enable-scroll {
                overflow-y: scroll;
            }
        }
    }
</style>

