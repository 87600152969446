<template>
    <div id="case">
        <div class="content-grid">
            <component 
                v-for="(component, index) in content.contentBlocks"
                :is="component.name"
                :content="component.data" 
                :index="index"
                :key="component.name+'_'+index"
            />
        </div>
    </div>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'
    
    export default {
        mixins: [DefaultPage],
        props: {
            content: {
                type: Object,
                required: true
            }
        },
        data: function() {
            return {};
        },
        computed: {
            caseActive: {
                get() {
                    return this.$store.state.caseActive;
                },
                set(bool) {
                    this.$store.commit('setCaseActive', bool)
                }
            },
        },
        methods: {

        },
        mounted() {
            
        },
        beforeDestroy() {
           
        }

    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    #case {
        width: 100%;
        max-width: 100vw;
        overflow: hidden;

        .content-grid {
            padding: 2rem 1rem 2.5rem 1rem;
            width: 100%;
            max-width: 1280px;
            margin: 0 auto;
            overflow: visible;

            // Flex fallback
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            
            // Switch to grid
            display: grid;
            grid-auto-flow: row dense;
            grid-template-columns: repeat(12,  1fr);
            gap: 1rem;

            @include mobile {
                padding: 2rem 2rem 2.5rem 2rem;
            }
        }
    }
</style>

