<template>
    <div id="expertises">
        <ul>
            <li 
                v-for="(expertise, index) in expertises" 
                :key="'expertise_'+index"
            >
                <article>
                    <h2>{{expertise.title}}</h2>
                    <span v-html="expertise.body"></span>
                </article>
            </li>
        </ul>
    </div>
</template>

<script>
    import DefaultPage from '../../mixins/DefaultPage.vue'

    
    export default {
        mixins: [DefaultPage],
        components: {
            
        },
        data: function() {
            return {

            }
        },
        computed: {
            expertises() {
                return this.$store.state.preloadedPages.expertises;
            }
        },
        methods: {

        },
        mounted() {
            
        },
        created() {
           
        }

    }
</script>

<style lang="scss">
    @import '@/scss/helper.scss';

    #expertises {
        ul {
            margin-top: 1rem;
            list-style: none;
            
            li {
                flex-basis: 100%;
                width: 100%;
                height: 100%;
                background: $white;
                color: $blue;
                margin: 1px 0;
                margin-right: 1px;

                &:last-child {
                    margin-right: 2rem;
                }

                article {
                    padding: 2rem 1rem 2rem 1rem;

                    p:not(:last-child) {
                        padding-bottom: 1em;
                    }
                }

                @include orientationSwitch {
                    flex-basis: 25rem;
                    width: 25rem;
                    margin: 0;
                    margin-right: 1px;

                    article {
                        padding: 4rem 2rem 2rem 2rem;
                    }
                }
            }

            @include orientationSwitch {
                margin-top: 0;
                height: 100%;
                display: flex;
            }
        }
    }
</style>

