import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // Settings
        initializing: true,
        debug: false,
        websiteData: null,
        preloadedPages: null,

        // State
        isHome: false,
        pageLoading: true,
        scrollHorizontal: true,
        hideOverflow: false,
        isMobile: null,
        caseActive: false,
        closeCase: false,
        menuColorClass: 'dark',

        // Share/OG card defaults
        og: {
            url: 'https://kollektiv-media.com/',
            image: 'https://kollektiv-media.com/imager/images/contact/919/contact_3998b7d57f5b9d921cfe1e5273bfd011.jpg'
        },
        
        // Language
        defaultLanguage: 'nl',
        currentLanguage: 'nl',
        availableLanguages: null,
        availableTranslations: null,

        // API endpoints
        apiEndpoints: {
            'website': {
                url: '/api/v1/website.json'
            },
            'page': {
                url: '/api/v1/page/'
            }
        },
    },
    mutations: {
        setInitializing: function(state, status) {
            state.initializing = status;
        },
        setPageLoading: function(state, status) {
            state.pageLoading = status;
        },
        setWebsiteData: function(state, websiteData) {
            state.websiteData = websiteData['settings'];
            state.preloadedPages = websiteData['pages'];
        },
        setAvailableLanguages: function(state, arrayOfLanguages) {
            state.availableLanguages = arrayOfLanguages;
        },
        setCurrentLanguage: function(state, language) {
            state.currentLanguage = language;
        },
        setAvailableTranslations: function(state, translations) {
            state.availableTranslations = translations;
        },
        setIsHome: function(state, bool) {
            state.isHome = bool;
        },
        setIsMobile: function(state, bool) {
            state.isMobile = bool;
        },
        setCaseActive: function(state, bool) {
            state.caseActive = bool;
        },
        setCloseCase: function(state, bool) {
            state.closeCase = bool;
        },
    },
    actions: {
        setLanguage ({ commit, state }, language) {
            if (state.availableLanguages.indexOf(language) > -1) {
                commit('setCurrentLanguage', language);
                return true;
            } else {
                // language does not exist / 404
                return false;
            }
        },
    },
    getters: {
        langPrefix(state) {
            if(state.currentLanguage == state.defaultLanguage) return '/';
            return '/'+state.currentLanguage+'/';
        }
    }
})
